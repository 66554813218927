import React, { useState } from 'react';

import Modal from '../Modal/Modal';

import css from './priceBreakdown.module.css';
import { BuyerProtectionFeeModal } from './ListingCardBuyerProtectionModal';

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  // We are just checking the value for now
  // console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  return true;
};
function IconShieldCheck(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 16 16" height="1em" width="1em" {...props}>
      <path d="M5.338 1.59a61.44 61.44 0 00-2.837.856.481.481 0 00-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 002.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 00.101.025.615.615 0 00.1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 002.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 00-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 011.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 01-2.517 2.453 7.159 7.159 0 01-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 01-1.048-.625 11.777 11.777 0 01-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 012.185 1.43 62.456 62.456 0 015.072.56z" />
      <path d="M10.854 5.146a.5.5 0 010 .708l-3 3a.5.5 0 01-.708 0l-1.5-1.5a.5.5 0 11.708-.708L7.5 7.793l2.646-2.647a.5.5 0 01.708 0z" />
    </svg>
  );
}

export const PriceBreakDownModal = props => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  const handleSafetyClick = e => {
    e.preventDefault();
    //open new window with link
    window.open('/p/safety-and-security', '_blank');
  };

  return (
    <div>
      <Modal
        id="PriceBreakDownModal"
        {...props}
        isOpen={isOpen}
        onClose={e => {
          setOpen(false);
          e.stopPropagation();
          e.preventDefault();
        }}
        usePortal={true}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <h3>Price breakdown</h3>
        <hr />
        <div className={css.priceBreakDownContainer}>
          <div className={css.priceBreakDownBox}>
            <div className={css.priceBreakDownTitle}>Item</div>
            <div className={css.priceBreakDownPrice}>
              {props.isUturn ? props.discountPrice : props.price}
            </div>
          </div>
          <div className={css.priceBreakDownBox}>
            <div className={css.priceBreakDownTitle}>UTURN Buyer Protection Fee</div>
            <div className={css.priceBreakDownPrice}>
              {props.isUturn ? props.discountFee : props.fee}
            </div>
          </div>
        </div>
        <hr />
        <p>
          The UTURN buyer protection fee is a service fee that helps us maintain a secure platform
          for buyers and sellers. It is charged on all transactions.
        </p>
        <BuyerProtectionFeeModal />
      </Modal>

      <a onClick={handleOpen}>
        {props.formattedPriceWithFee} incl <IconShieldCheck />
      </a>
    </div>
  );
};
