import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/WishListPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/WishListPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/WishListPage/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryFavoritesError: null,
  currentPageResultIds: [],
};

const resultIds = data => (data?.data ? data.data.map(l => l.id) : []);

const WishListPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryFavoritesError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        queryInProgress: false,
        queryFavoritesError: payload,
      };

    default:
      return state;
  }
};

export default WishListPageReducer;

// ================ Action creators ================ //

export const queryFavoritesRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryFavoritesSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryFavoritesError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryFavoriteListings = queryParams => async (dispatch, getState, sdk) => {
  dispatch(queryFavoritesRequest(queryParams));

  try {
    // Fetch the current user data
    const userResponse = await sdk.currentUser.show();
    const currentUser = userResponse.data.data;

    // Extract favorites from user data
    const favorites = currentUser?.attributes?.profile?.privateData?.favorites || [];

    // Check if there are no favorites; if so, return an empty response
    if (favorites.length === 0) {
      dispatch(queryFavoritesSuccess({ data: [] }));
      return { data: [] };
    }

    // Prepare the query parameters
    const favoritesMaybe = { ids: favorites };
    const { perPage, ...rest } = queryParams;
    const params = { ...favoritesMaybe, ...rest, perPage };

    // Fetch listings based on favorites
    const response = await sdk.listings.query(params);

    dispatch(addMarketplaceEntities(response));
    dispatch(queryFavoritesSuccess(response));
    return response;
  } catch (e) {
    console.error(e);
    dispatch(queryFavoritesError(storableError(e)));
    throw e;
  }
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryFavoriteListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images', 'author.profileImage'],
    'fields.image': [
      `variants.${variantPrefix}`,
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    // 'limit.images': 1,
  });
};
