import { memo } from 'react';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';

export const ASSET_NAME = 'landing-page';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LATEST_ARRIVALS_SUCCESS = 'app/LandingPage/QUERY_LATEST_ARRIVALS_SUCCESS';
export const QUERY_UNDER_50_SUCCESS = 'app/LandingPage/QUERY_UNDER_50_SUCCESS';

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryLatestArrivalsSuccess = listingRefs => ({
  type: QUERY_LATEST_ARRIVALS_SUCCESS,
  payload: { listingRefs },
});

export const queryUnder50Success = listingRefs => ({
  type: QUERY_UNDER_50_SUCCESS,
  payload: { listingRefs },
});

const generateListingRefs = listings => listings.map(({ id, type }) => ({ id, type }));

const shuffleArray = array => array.sort(() => Math.random() - 0.5);

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const baseQueryOptions = {
    minStock: 1,
    stockMode: 'match-undefined',
    include: ['author', 'images', 'author.profileImage'],
    'fields.image': [
      `variants.${variantPrefix}`,
      'variants.square-small',
      'variants.square-small2x',
    ],
    'limit.images': 1,
    ...createImageVariantConfig(variantPrefix, 400, aspectRatio, 'scale'),
  };

  const queryOptions = {
    ...baseQueryOptions,
    authorId: '65f8cd51-0f4f-426c-b928-2ac7700c38a8',
  };

  const latestArrivalsQueryOptions = {
    ...baseQueryOptions,
    limit: 15,
  };

  const under50QueryOptions = {
    ...baseQueryOptions,
    price: ',5000',
  };

  try {
    const [listings, latestArrivals, under50Listings, pageAssets] = await Promise.all([
      sdk.listings.query(queryOptions),
      sdk.listings.query(latestArrivalsQueryOptions),
      sdk.listings.query(under50QueryOptions),
      dispatch(fetchPageAssets(pageAsset, true)),
    ]);

    const shuffledListings = shuffleArray(listings.data.data).slice(0, 15);
    const listingsRefs = generateListingRefs(shuffledListings);

    dispatch(
      addMarketplaceEntities({ ...listings, data: { ...listings.data, data: shuffledListings } })
    );
    dispatch(queryListingsSuccess(listingsRefs));

    const shuffledLatestArrivals = shuffleArray(latestArrivals.data.data).slice(0, 15);
    const latestArrivalsRefs = generateListingRefs(shuffledLatestArrivals);
    dispatch(
      addMarketplaceEntities({
        ...latestArrivals,
        data: { ...latestArrivals.data, data: shuffledLatestArrivals },
      })
    );
    dispatch(queryLatestArrivalsSuccess(latestArrivalsRefs));

    const shuffledUnder50 = shuffleArray(under50Listings.data.data).slice(0, 15);
    const under50ListingRefs = generateListingRefs(shuffledUnder50);
    dispatch(
      addMarketplaceEntities({
        ...under50Listings,
        data: { ...under50Listings.data, data: shuffledUnder50 },
      })
    );
    dispatch(queryUnder50Success(under50ListingRefs));

    return listings;
  } catch (error) {
    console.error('Error loading data:', error);
    throw error;
  }
};
