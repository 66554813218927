import { GetMarselloInformation } from '../../util/api';

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  try {
    // Call the async function and wait for the result
    const marselloData = await GetMarselloInformation(params, search, config);
    // Dispatch the result to your reducer
    dispatch({
      type: 'LOAD_MARSELLO_DATA_SUCCESS',
      payload: marselloData,
    });
  } catch (error) {
    // Handle any error that occurs during the async call
    dispatch({
      type: 'LOAD_MARSELLO_DATA_FAILURE',
      payload: error.message,
    });
  }
};
