import React, { useState } from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';
import { IconSpinner } from '..';

const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    variants,
    dimensions,
    ...rest
  } = props;

  // State to track image loading
  const [isLoaded, setIsLoaded] = useState(false);

  const classes = classNames(rootClassName || css.root, className, {
    [css.loaded]: isLoaded, // Adding a class once the image is loaded
  });

  if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  const imageVariants = image.attributes.variants;

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most likely just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
    onLoad: () => setIsLoaded(true), // Update the state when the image has loaded
  };

  // Get the first image variant
  const firstVariant = imageVariants[variants[0]];
  if (firstVariant) {
    imgProps.src = firstVariant.url;
  }

  return (
    <>
      {!isLoaded && (
        <div className={css.imagePlaceholder}>
          <IconSpinner />
        </div>
      )}
      <img alt={alt} {...imgProps} style={{ display: isLoaded ? 'block' : 'none' }} />
    </>
  );
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  variants: arrayOf(string).isRequired,
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImage;
