import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './ContactUsPage.module.css';
import { SendContactUsForm } from '../../util/api';

export const ContactUsComponent = props => {
  const topbar = <TopbarContainer />;

  const [formValues, setFormValues] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendForm = () => {
    setLoading(true);
    setError('');
    SendContactUsForm({
      fullName: formValues.fullName,
      email: formValues.email,
      message: formValues.message,
    })
      .then(() => {
        setSuccess(true);
        setError('');
      })
      .catch(e => {
        setSuccess(false);
        if (e.validationError) {
          if (e.email) {
            setError(e.email);
          } else if (e.fullName) {
            setError(e.fullName);
          } else if (e.message) {
            setError(e.message);
          }
        } else {
          setError('An error occurred while sending the form. Please try again later.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Page
      title="Contact Us"
      scrollingDisabled={false}
      author="Uturn"
      description="Contact us for any queries or feedback"
      //   facebookImages={facebookImages}
      //   twitterImages={twitterImages}
      //   schema={{
      //     '@context': 'http://schema.org',
      //     '@type': 'Product',
      //     description: description,
      //     name: schemaTitle,
      //     image: schemaImages,
      //     offers: {
      //       '@type': 'Offer',
      //       url: productURL,
      //       ...schemaPriceMaybe,
      //       availability: schemaAvailability,
      //     },
      //   }}
    >
      <LayoutSingleColumn
        // className={css.pageRoot}
        topbar={topbar}
        footer={<FooterContainer />}
      >
        <div className={css.pageContainer}>
          <h2>Send us a message</h2>
          {error && <div className={css.error}>{error}</div>}
          {success && <div className={css.success}>Message sent successfully</div>}
          {!success && (
            <>
              <div className={css.formContainer}>
                <label>
                  Full name <span className={css.required}>*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="John Doe"
                  disabled={loading}
                  onChange={e => {
                    setFormValues({
                      ...formValues,
                      fullName: e.target.value,
                    });
                  }}
                />

                <label>
                  Email Address <span className={css.required}>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="john.doe@example.com"
                  disabled={loading}
                  onChange={e => {
                    setFormValues({
                      ...formValues,
                      email: e.target.value,
                    });
                  }}
                />

                <label>
                  Message <span className={css.required}>*</span>
                </label>
                <textarea
                  name="message"
                  rows="5"
                  placeholder="Your message here"
                  disabled={loading}
                  onChange={e => {
                    setFormValues({
                      ...formValues,
                      message: e.target.value,
                    });
                  }}
                />

                <button onClick={sendForm} className={css.sendFormButton} disabled={loading}>
                  Send
                </button>
              </div>
            </>
          )}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ContactUsComponent.propTypes = {};

const mapStateToProps = state => {
  return {};
};

const ContactUsPage = compose(connect(mapStateToProps))(ContactUsComponent);

export default ContactUsPage;
