import React from 'react';
import Select from 'react-select';

export const conditionOptions = [
  {
    value: 'brand_new',
    label: 'Brand New',
    description: 'Unused with original packaging or tags.',
  },
  {
    value: 'like_new',
    label: 'Like new',
    description: 'Mint condition pre-owned or new without tags.',
  },
  {
    value: 'used_excellent',
    label: 'Excellent',
    description: 'Light used but no noticeable flaws.',
  },
  {
    value: 'used_good',
    label: 'Good',
    description: 'Minor flaws or signs of wear, to be noted in the description or photos.',
  },
  {
    value: 'used_fair',
    label: 'Fair',
    description: 'Obvious flaws or signs of wear, to be noted in the description or photos.',
  },
];

const options = conditionOptions;

export const ConditionSelect = ({ value, onOptionSelect }) => {
  const handleOnChange = selectedOptions => {
    onOptionSelect(selectedOptions);
  };

  const formatOptionLabel = ({ value, label, description }) => (
    <div>
      <div>
        <b>{label}</b>
      </div>
      <div>{description}</div>
    </div>
  );

  //find value in options otherwise use option[0]
  const defaultValue = options.find(option => option.value === value) || options[0];

  return (
    <>
      <label>Condition</label>
      <Select
        isSearchable={false}
        onChange={o => handleOnChange(o)}
        defaultValue={options[0]}
        value={defaultValue}
        formatOptionLabel={formatOptionLabel}
        options={options}
      />
    </>
  );
};

export const FindConditionLabelFromValue = value => {
  const condition = conditionOptions.find(option => option.value === value);
  return condition ? condition.label : '';
};
